import React, { PureComponent } from "react";
import FormUpload from "./Form";
class Upload extends PureComponent {
  render() {
    return (
      <div className="upload-container">
        <FormUpload />
      </div>
    );
  }
}

export default Upload;
